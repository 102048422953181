import CreateEditCharacter from './components/createEditCharacter'
import Grid from '@mui/material/Grid';
import './App.css';
import { AppBar, Avatar, Box, Button, createTheme, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Select, TextField, ThemeProvider, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/home';
import React, { useEffect, useState } from 'react';
import CharacterInfos from './components/characterInfos';
import strings from './components/localization'
import LogEntry from './components/logEntry';
import Login from './components/login';
import { getDiscordUrl, getSnackBarOptions } from './common/common';
import CreateEditDMLog from './components/createeditDmLog';
import DMLogs from './components/dmlogs';
import { canConnecToApi } from './repository/repository';
import { useSnackbar } from 'notistack';
import { MobileMenu } from './MobileMenu';
import { LinkContainer } from 'react-router-bootstrap';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

function App() {
  const largeScreen = useMediaQuery('(min-width:600px)');

  const { enqueueSnackbar } = useSnackbar();

  const selectedTheme = sessionStorage.getItem('theme') ?? 'light'
  const selectedLang = sessionStorage.getItem('lang') ?? 'en';

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  var storedUser = JSON.parse(sessionStorage.getItem('user'))

  const [theme, setTheme] = useState(selectedTheme === 'light' ? lightTheme : darkTheme);
  const [mobileOpen, setMobileOpen] = useState(null);
  const [lang, setLang] = useState(selectedLang);
  const [user, setUser] = useState(storedUser);
  const [openBugReport, setOpenBugReport] = useState(false);
  const [errorReport, setErrorReport] = useState({ title: '', desc: '', user: '' });
  const [canLogBug, setCanLogBug] = useState(false)

  const open = Boolean(mobileOpen);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  }

  const handleLangChange = (event) => {
    setLang(event.target.value);
    strings.setLanguage(event.target.value);
    sessionStorage.setItem('lang', event.target.value)
  }

  const toggleColorMode = (event) => {
    var currentTheme = theme.palette.mode
    currentTheme === 'dark' ? setTheme(lightTheme) : setTheme(darkTheme)
    sessionStorage.setItem('theme', theme.palette.mode === 'dark' ? 'light' : 'dark')
  }

  const logout = (event) => {
    sessionStorage.removeItem('user')
    setUser(null)
  }

  const handleCloseBugReport = (event) => {
    setOpenBugReport(false)
  }

  const sendReport = (event) => {

    fetch('https://dev.azure.com/guilde-aventure/La%20Guilde%20Logs%20Aventure/_apis/wit/workitems/$Issue?api-version=7.0', {
      method: 'POST', headers: {
        "Authorization": "Basic NXl3a2pmbjJhdjZlaW5yNmtxem9uN2Jkc2JwdGVkanBjZXV5YXFwamt4YXQyNmZ6a3c0cQ==",
        "Content-Type": "application/json-patch+json"
      }, body: `[{op:"add", path:"/fields/System.Title", value:"${errorReport.title} - from : ${errorReport.user}", from: null}, {op:"add", path:"/fields/System.Description", value:"${errorReport.desc}", from: null}]`
    })
      .then((data) => {
        console.log(data)
        enqueueSnackbar(strings.savesuccess, getSnackBarOptions('success'))
        setErrorReport({ title: '', desc: '', user: reportError.user })
        setOpenBugReport(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      var localUser = sessionStorage.getItem('user')
      if (localUser !== user) {
        setUser(JSON.parse(localUser))
      }
    }, 1000)

    setErrorReport(prevState => {
      var buffer = Object.assign({}, prevState)
      buffer.user = user ? user.username : ''
      return buffer
    })
  }, [user, location.pathname])

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;

    if (!sessionStorage.getItem('lang') === null) {
      const langCode = userLang.length > 2 ? userLang.substring(0, 2) : userLang
      setLang(langCode)
    }
  }, [])

  useEffect(() => {
    setCanLogBug(user !== undefined)
  }, [user])

  const changeBugAttr = (event) => {
    const attr = event.target.dataset.target

    setErrorReport(prevState => {
      var buffer = Object.assign({}, prevState)
      buffer[attr] = event.target.value
      return buffer
    })
  }

  useEffect(() => {
    try {
      canConnecToApi()
        .then(resp => {
          console.log('API connection : OK')
        })
        .catch(err => {
          console.error(err)
          enqueueSnackbar(strings.cannotreachserver, getSnackBarOptions('error'))
        })
    } catch (err) {
      console.error(err)
      enqueueSnackbar(strings.cannotreachserver, getSnackBarOptions('error'))
    }
  }, [location.pathname, enqueueSnackbar])

  return (
    <div className="App">
      <Dialog id='alert-dialog'
        fullScreen={!largeScreen}
        maxWidth={largeScreen ? 'xs' : 'md'}
        open={openBugReport} onClose={handleCloseBugReport}>
        <DialogTitle id='alert-dialog-title'>Found a bug ?</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <TextField xs={12}
                fullWidth
                id="buguser"
                label='Who are you ?'
                value={errorReport.user}
                onChange={changeBugAttr}
                inputProps={{ 'data-target': 'title' }}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <TextField xs={12}
                fullWidth
                id="bugtitle"
                label='What happened ?'
                value={errorReport.title}
                onChange={changeBugAttr}
                inputProps={{ 'data-target': 'title' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <TextField xs={12}
                fullWidth
                multiline
                rows={4}
                id="bugrepro"
                label='What did you do (describe as much as possible) ?'
                value={errorReport.desc}
                onChange={changeBugAttr}
                inputProps={{ 'data-target': 'desc' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={sendReport} variant="contained" color="info" sx={{ width: 1 / 2 }}>{strings.send}</Button>
          <Button onClick={handleCloseBugReport} sx={{ width: 1 / 2 }} autoFocus>
            {strings.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ flexGrow: 1, position: 'sticky' }}>
              <AppBar position="static">
                {largeScreen ?
                  <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        {user && <LinkContainer to='/'>
                          <Button color="inherit">
                            <Avatar alt={user.username} src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}`} sx={{ marginRight: 1 }} />
                            <Typography variant='button'>{user.username}</Typography>
                          </Button>
                        </LinkContainer>}
                        {!user && <LinkContainer to='/'><Button color='inherit'>{strings.home}</Button></LinkContainer>}
                        <LinkContainer to='create'><Button color="inherit">{strings.create}</Button></LinkContainer>
                        <LinkContainer to='dmlogs'><Button color="inherit">{strings.dmlogs}</Button></LinkContainer>
                      </Grid>
                      <Grid item xs />
                      <Grid item>
                        <Tooltip title={strings.foundbug}>
                          <Button onClick={() => setOpenBugReport(true)} disabled={!canLogBug}>
                            🐛
                          </Button>
                        </Tooltip>
                        <Select value={lang} sx={{ color: 'white', height: '2em' }} onChange={handleLangChange}>
                          <MenuItem value={'en'}>🇬🇧</MenuItem>
                          <MenuItem value={'fr'}>🇫🇷</MenuItem>
                        </Select>
                        <IconButton sx={{ ml: 1 }} onClick={toggleColorMode} color="inherit">
                          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        {user && <Button color="inherit" onClick={logout}>{strings.logout}</Button>}
                        {!user && <Button color="inherit" href={getDiscordUrl()}>{strings.login}</Button>}
                      </Grid>
                    </Grid>
                  </Toolbar>
                  :
                  <MobileMenu
                    mode={theme.palette.mode}
                    mobileOpen={mobileOpen}
                    lang={lang} user={user} open={open}
                    handleDrawerToggle={handleDrawerToggle}
                    handleLangChange={handleLangChange}
                    toggleColorMode={toggleColorMode}
                    logout={logout}>
                  </MobileMenu>}
              </AppBar>
            </Box>
          </Grid>
          <Grid item xs={0} lg={3}></Grid>
          <Grid item xs={12} lg={6}>
            {/* <Outlet />  */}
          </Grid>
          <Grid item xs={0} lg={3}></Grid>
        </Grid>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="create" element={<CreateEditCharacter />} />
          <Route path="edit/:pcid" element={<CreateEditCharacter />} />
          <Route path="infos/:pcid" element={<CharacterInfos />} />
          <Route path="infos/:pcid/log/:advid" element={<LogEntry />} />
          <Route path="infos/:pcid/addlog/" element={<LogEntry />} />
          <Route path="infos/:pcid/log" element={<LogEntry />} />
          <Route path="login" element={<Login />} />
          <Route path="dmlogs" element={<DMLogs />} />
          <Route path="createdmlog" element={<CreateEditDMLog />} />
          <Route path="dmlog/:id" element={<CreateEditDMLog />} />
        </Routes>
      </ThemeProvider>
    </div >
  );


}

export default App;
