import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import strings from "./localization";

export function CharacterRibbon(character) {
    return <Grid container>
        <Grid item xs={4}>
            <Typography variant="h3" component="h2">
                {character.name}
            </Typography>
        </Grid>
        <Grid item xs={8}>
            <Grid container>
                <Grid item xs={4}>
                    <Stack>
                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                            {strings.class} : {character.pcClass}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                            {strings.race} : {character.race}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                            {strings.level} : {character.lvl ?? 0}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                            {strings.gold} : {character.gold ?? 0} {strings.gp}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                            {strings.downtime} : {character.dt ?? 0} {strings.days}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ textAlign: 'left' }}>
                            {strings.setting} : {character.setting}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}><hr /></Grid>
    </Grid>;
}
