import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import strings from "./localization"
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ItemEntry from "./itemEntry";
import { useNavigate, useParams } from "react-router-dom";
import { adventureEntryProp, containerStyle } from "./styles/common";
import { CharacterRibbon } from "./characterRibbon";
import { deleteLogEntry, getLogs, getPC, getUser, saveLogEntry } from "../repository/repository";
import Image from "mui-image";
import { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { useSnackbar } from 'notistack';
import { getSnackBarOptions } from "../common/common";
import ErrorBoundary from "../errorBoundary";
import WarningDialog from "../warningDialog";
import { ErrorMessage } from "./ErrorMessage";
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

export default function LogEntry() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const tmpEntry = {
        type: 'adventure',
        date: Date.now(),
        code: '',
        lvlgain: '',
        goldgain: '',
        dtgain: '',
        magicitems: [],
        notes: '',
        consumables: [],
        storyawards: [],
        dm: ''
    }

    const unclickable = { pointerEvents: 'none' }

    var params = useParams()

    var pcId = params['pcid']
    var advId = params['advid'] ? params['advid'] : undefined

    const user = JSON.parse(sessionStorage.getItem('user'))
    const key = sessionStorage.getItem('key')

    const [loaded, setLoaded] = useState(false)
    const [selectedType, setSelectedType] = useState(tmpEntry.type)
    const [typeLocked, setTypeLocked] = useState(false)
    const [pageError, setPageError] = useState(false)

    const [showAdventure, setShowAdventure] = useState('none')
    const [showDTSpend, setShowDTSpend] = useState('none')
    const [showPurchase, setShowPurchase] = useState('none')
    const [showDmLogs, setShowDmLogs] = useState('none')
    const [showTrade, setShowTrade] = useState('none')
    const [character, setCharacter] = useState({})
    const [logAdventureEntry, setLogAdventureEntry] = useState(tmpEntry)
    const [selectedItemTrade, setSelectedItemTrade] = useState('')

    const [magicitems, setMagicItems] = useState([])
    const [openModalMI, setOpenModalMI] = useState(false)
    const [editedMI, setEditedMI] = useState({})

    const [consumables, setConsumables] = useState([])
    const [openModalCo, setOpenModalCo] = useState(false)
    const [editedCo, setEditedCo] = useState({})

    const [storyawards, setStoryAwards] = useState([])
    const [openModalSA, setOpenModalSA] = useState(false)
    const [editedSA, setEditedSA] = useState({})

    const [craftingTokens, setCraftingTokens] = useState(0)
    const [invalidTokens, setInvalidTokens] = useState(false)
    const [hasError, setHasError] = useState(true)

    const [openWarningAlert, setOpenWarningAlert] = useState(false)


    useEffect(() => {
        if (loaded) {
            return
        }

        getPC(pcId).then((json) => {
            setCharacter(json)
            setLoaded(true)
        }).catch((error) => {
            enqueueSnackbar(error, getSnackBarOptions('error'))

            const options = getSnackBarOptions('warning')
            options.onExited = () => {
                navigate('/');
            }
            enqueueSnackbar(strings.redirecting, options)
            setPageError(true)
        })

        if (advId !== undefined) {
            setTypeLocked(false)
            getLogs(pcId, advId)
                .then((json) => {
                    const data = JSON.parse(json)
                    setLogAdventureEntry(data.log)
                    setSelectedType(data.log.type)
                    setMagicItems(data.log.magicitems)
                    setConsumables(data.log.consumables)
                    setStoryAwards(data.log.storyawards)

                    if (data.log.itemout !== undefined) {
                        setSelectedItemTrade(data.log.itemout)
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(error, getSnackBarOptions('error'))
                    const options = getSnackBarOptions('warning')
                    options.onExited = () => {
                        navigate(`/infos/${pcId}`);
                    }
                    enqueueSnackbar(strings.redirecting, options)
                    setPageError(true)
                })
        }
    })

    useEffect(() => {
        if (selectedType === 'adventure') {
            if (logAdventureEntry.code.trim() === '' ||
                logAdventureEntry.goldgain === undefined ||
                logAdventureEntry.dtgain === undefined) {
                setHasError(true)
                return
            }
            setHasError(false)
            return
        }
        if (selectedType === 'dtspend') {
            if (logAdventureEntry.dtgain === undefined ||
                logAdventureEntry.dtgain >= 0) {
                setHasError(true)
                return
            }
            setHasError(false)
            return
        }
        if (selectedType === 'purchase') {
            if (logAdventureEntry.goldgain === undefined ||
                logAdventureEntry.goldgain === 0) {
                setHasError(true)
                return
            }
            setHasError(false)
            return
        }
        if (selectedType === 'dmreward') {
            if (logAdventureEntry.tokenused === undefined ||
                logAdventureEntry.tokenused === null ||
                logAdventureEntry.tokenused === 0) {
                setHasError(true)
                return
            }
            setHasError(false)
            return
        }
        if (selectedType === 'trade') {
            if (logAdventureEntry.itemout === undefined ||
                logAdventureEntry.itemout === null) {
                setHasError(true)
                return
            }
            setHasError(false)
            return
        }

        setHasError(true)
    }, [logAdventureEntry, selectedType])

    useEffect(() => {
        if (!craftingTokens) {
            getUser(key, user._id)
                .then((resp) => {
                    setCraftingTokens(resp.user.craftingtokens ?? 0)
                })
                .catch((error) => {
                    enqueueSnackbar(error, getSnackBarOptions('error'))
                    const options = getSnackBarOptions('warning')
                    options.onExited = () => {
                        navigate('/');
                    }
                    enqueueSnackbar(strings.redirecting, options)
                    setPageError(true)
                })
        }
    }, [enqueueSnackbar, key, navigate, user])

    useEffect(() => {
        if (selectedType === 'adventure') {
            setShowAdventure('visible')

        } else {
            setShowAdventure('none')
        }
        if (selectedType === 'dtspend') {
            setShowDTSpend('visible')
        } else {
            setShowDTSpend('none')
        }
        if (selectedType === 'purchase') {
            setShowPurchase('visible')
        } else {
            setShowPurchase('none')
        }
        if (selectedType === 'dmreward') {
            setShowDmLogs('visible')
        } else {
            setShowDmLogs('none')
        }
        if (selectedType === 'trade') {
            setShowTrade('visible')
        } else {
            setShowTrade('none')
        }

    }, [selectedType])

    const changeAttr = (event) => {
        const attr = event.target.dataset.target

        setLogAdventureEntry(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const changeTokensWithCheck = (event) => {
        const qty = event.target.value
        const attr = event.target.dataset.target

        if (qty > craftingTokens) {
            setInvalidTokens(true)
        } else {
            setInvalidTokens(false)
        }

        setLogAdventureEntry(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const saveMI = (event) => {
        if (editedMI.idx === undefined) {
            setMagicItems(prevState => {
                var mitem = Object.assign({}, editedMI)
                mitem.idx = idxMagicItems++
                prevState.push(mitem)
                return prevState
            })
        } else {
            const idx = magicitems.findIndex(m => m.idx === editedMI.idx)

            if (idx < 0) {
                enqueueSnackbar(`${strings.magicitem} ${strings.notFound}. ${strings.tryReloading}`, getSnackBarOptions('error'))
            } else {
                setMagicItems(prevState => {
                    prevState[idx] = editedMI
                    return prevState
                })
            }
        }
        setEditedMI({ name: '', desc: '' })
        setOpenModalMI(false)
    }

    const saveCo = (event) => {
        if (editedCo.idx === undefined) {
            setConsumables(prevState => {
                var co = Object.assign({}, editedCo)
                co.idx = idxMagicItems++
                prevState.push(co)
                return prevState
            })
        } else {
            const idx = consumables.findIndex(c => c.idx === editedCo.idx)
            if (idx < 0) {
                enqueueSnackbar(`${strings.consumable} ${strings.notFound}. ${strings.tryReloading}`, getSnackBarOptions('error'))
            } else {
                setConsumables(prevState => {
                    prevState[idx] = editedCo
                    return prevState
                })
            }
        }
        setEditedCo({ name: '', qty: '' })
        setOpenModalCo(false)
    }

    const saveSA = (event) => {
        if (editedSA.idx === undefined) {
            setStoryAwards(prevState => {
                var sa = Object.assign({}, editedSA)
                sa.idx = prevState.length
                prevState.push(sa)
                return prevState
            })
        } else {
            const idx = storyawards.findIndex(c => c.idx === editedSA.idx)
            if (idx < 0) {
                enqueueSnackbar(`${strings.storyawards} ${strings.notFound}. ${strings.tryReloading}`, getSnackBarOptions('error'))
            } else {
                setStoryAwards(prevState => {
                    prevState[idx] = editedSA
                    return prevState
                })
            }
        }

        setEditedSA({ name: '', desc: '' })
        setOpenModalSA(false)
    }

    const handleModalMI = (event) => {
        if (event.target.dataset.objid !== undefined) {
            const idx = Number(event.target.dataset.objid)
            const mi = magicitems.find(mi => mi.idx === idx)
            setEditedMI(mi)
        } else {
            setEditedMI({ name: '', desc: '', rarity: '' })
        }
        setOpenModalMI(true)
    }

    const handleModalCo = (event) => {
        if (event.target.dataset.objid !== undefined) {
            const idx = Number(event.target.dataset.objid)
            const consumable = consumables.find(co => co.idx === idx)
            setEditedCo(consumable)
        } else {
            setEditedCo({ name: '', qty: '' })
        }
        setOpenModalCo(true)
    }

    const handleModalSA = (event) => {
        if (event.target.dataset.objid !== undefined) {
            const idx = Number(event.target.dataset.objid)
            const storyaward = storyawards.find(sa => sa.idx === idx)
            setEditedSA(storyaward)
        } else {
            setEditedSA({ name: '', desc: '' })
        }
        setOpenModalSA(true)
    }

    const closeMI = (event) => {
        setEditedMI({ name: '', desc: '', rarity: '' })
        setOpenModalMI(false)
    }

    const closeCo = (event) => {
        setEditedCo({ name: '', qty: '' })
        setOpenModalCo(false)
    }

    const closeSA = (event) => {
        setEditedSA({ name: '', desc: '' })
        setOpenModalSA(false)
    }

    const changeEditedMI = (event) => {
        const attr = event.target.dataset.target

        setEditedMI(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const changeEditedCo = (event) => {
        const attr = event.target.dataset.target

        setEditedCo(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const changeEditedSA = (event) => {
        const attr = event.target.dataset.target

        setEditedSA(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const handleDeleteMI = (event) => {
        setOpenModalMI(false)

        const idx = consumables.findIndex(c => c.idx === editedMI.idx)
        const buffer = magicitems.slice()
        buffer.splice(idx, 1)

        setMagicItems(buffer)
        setEditedMI({ name: '', desc: '', rarity: '' })
    }

    const handleDeleteCo = (event) => {
        setOpenModalCo(false)

        const idx = consumables.findIndex(c => c.idx === editedCo.idx)
        const buffer = consumables.slice()
        buffer.splice(idx, 1)

        setConsumables(buffer)
        setEditedCo({ name: '', qty: '' })
    }

    const handleDeleteSA = (event) => {
        setOpenModalSA(false)

        const idx = event.target.dataset.objid
        const buffer = storyawards.slice()
        buffer.splice(idx, 1)

        setStoryAwards(buffer)
        setEditedSA({ name: '', desc: '' })
    }

    const handleDateChange = (value, keyboardInputValue) => {

        if (value === undefined || value === null) {
            return
        }

        setLogAdventureEntry(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.date = value.$d
            return buffer
        })
    }

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value)

        setLogAdventureEntry(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.type = event.target.value
            return buffer
        })
    }

    const handleMITypeChange = (event) => {
        setEditedMI(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.rarity = event.target.value
            return buffer
        })
    }

    const handleOMTradeChange = (event) => {
        setSelectedItemTrade(event.target.value)

        setLogAdventureEntry(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.itemout = event.target.value
            return buffer
        })
    }

    const handleCloseWarningAlert = (event) => {
        setOpenWarningAlert(false)
    }

    const handleDeleteEntryLog = (event) => {
        setOpenWarningAlert(false)

        const user = JSON.parse(sessionStorage.getItem('user'))
        const key = sessionStorage.getItem('key')

        deleteLogEntry(pcId, logAdventureEntry._id, user._id, key)
            .then(json => {
                const options = getSnackBarOptions('success')
                options.autoHideDuration = 500

                options.onExited = () => {
                    navigate(`/infos/${pcId}`);
                }

                enqueueSnackbar(strings.deletesuccess, options)
            }).catch((err) => {
                enqueueSnackbar(err, getSnackBarOptions('error'))
                setPageError(true)
            })
    }

    const onClickSave = (event) => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        const key = sessionStorage.getItem('key')
        var buffer = Object.assign({}, logAdventureEntry)

        buffer.magicitems = magicitems
        buffer.consumables = consumables
        buffer.storyawards = storyawards

        if (buffer.type === 'dmreward') {
            buffer.dmrewardtype = 'crafttoken'
        }

        saveLogEntry(character._id, buffer, user._id, key)
            .then((json) => {

                const options = getSnackBarOptions('success')
                options.autoHideDuration = 500

                options.onExited = () => {
                    navigate(`/infos/${pcId}`);
                }

                enqueueSnackbar(strings.savesuccess, options)
            })
            .catch((err) => {
                enqueueSnackbar(err, getSnackBarOptions('error'))
                setPageError(true)
            })
    }

    var idxMagicItems = 0
    var idxConsumables = 0
    var idxStoryAwards = 0

    if (pageError) {
        return <ErrorMessage />
    }

    if (!loaded) {
        return <CircularProgress sx={{ color: 'Scrollbar' }} />;
    }

    return (
        <ErrorBoundary>
            <Grid container sx={containerStyle} className='mainContainer'>
                <WarningDialog message={`${strings.deletewarningnoquestionmark} ${strings.logentry} ?`} message2={strings.contentlostwarning} open={openWarningAlert}
                    handleClose={handleCloseWarningAlert} handleYes={handleDeleteEntryLog} handleCancel={handleCloseWarningAlert} />
                <Modal
                    open={openModalMI}>
                    <Box sx={style}>
                        <Grid container>
                            <Grid item xs={12} md={8}>
                                <TextField fullWidth label={strings.magicitems} value={editedMI.name} onChange={changeEditedMI} inputProps={{ 'data-target': 'name' }} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box>
                                    <FormControl fullWidth disabled={typeLocked}>
                                        <InputLabel id='entry-om-rarity'>{strings.rarity}</InputLabel>
                                        <Select value={editedMI.rarity} label="Log Type" labelId='entry-om-rarity'
                                            onChange={handleMITypeChange}>
                                            <MenuItem value='common'>{strings.common}</MenuItem>
                                            <MenuItem value='uncommon'>{strings.uncommon}</MenuItem>
                                            <MenuItem value='rare'>{strings.rare}</MenuItem>
                                            <MenuItem value='veryrare'>{strings.veryrare}</MenuItem>
                                            <MenuItem value='legendary'>{strings.legendary}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                <TextField fullWidth label={strings.desc} multiline rows={4} value={editedMI.desc} onChange={changeEditedMI} inputProps={{ 'data-target': 'desc' }} />
                            </Grid>
                            <Grid item xs={4} sx={{ marginTop: '10px' }}>
                                <Button variant="contained" onClick={saveMI} sx={{ marginRight: '2em' }}>{strings.ok}</Button>
                                <Button variant="outlined" onClick={closeMI}>{strings.cancel}</Button>
                            </Grid>
                            <Grid xs={8} sx={{ marginTop: '10px' }}>
                                <Box justifyContent='flex-end' sx={{ display: 'flex' }}>
                                    {(editedMI.idx !== undefined) ? <Button variant="contained" color="error" onClick={handleDeleteMI}>{strings.deletebutton}</Button> : ''}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={openModalCo}>
                    <Box sx={style}>
                        <Grid container sx={{ width: '80%', margin: 'auto' }}>
                            <Grid item xs={12} md={8}>
                                <TextField fullWidth label={strings.consumable} value={editedCo.name} onChange={changeEditedCo} inputProps={{ 'data-target': 'name' }} />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ alignItems: 'right' }}>
                                <TextField type='number' fullWidth label={strings.quantity} value={editedCo.qty} onChange={changeEditedCo} error={invalidTokens}
                                    inputProps={{ 'data-target': 'qty', inputMode: 'numeric', pattern: '[0-9]*' }} />
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                <TextField fullWidth label={strings.desc} multiline rows={4} value={editedCo.desc} onChange={changeEditedCo} inputProps={{ 'data-target': 'desc' }} />
                            </Grid>
                            <Grid item xs={4} sx={{ marginTop: '10px' }}>
                                <Button variant="contained" onClick={saveCo} sx={{ marginRight: '2em' }}>{strings.ok}</Button>
                                <Button variant="outlined" onClick={closeCo}>{strings.cancel}</Button>
                            </Grid>
                            <Grid xs={8} sx={{ marginTop: '10px' }}>
                                <Box justifyContent='flex-end' sx={{ display: 'flex' }}>
                                    {(editedCo.idx !== undefined) ? <Button variant="contained" color="error" onClick={handleDeleteCo}>{strings.deletebutton}</Button> : ''}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={openModalSA}>
                    <Box sx={style}>
                        <Grid container>
                            <Grid item xs={12}>
                                <TextField fullWidth label={strings.storyawards} value={editedSA.name} onChange={changeEditedSA} inputProps={{ 'data-target': 'name' }} />
                            </Grid>
                            <Grid item xs={12} sx={{ alignItems: 'right' }}>
                                <TextField fullWidth label={strings.desc} multiline rows={4} value={editedSA.desc} onChange={changeEditedSA} inputProps={{ 'data-target': 'desc' }} />
                            </Grid>
                            <Grid item xs={4} sx={{ marginTop: '10px' }}>
                                <Button variant="contained" onClick={saveSA}>{strings.ok}</Button>
                                <Button variant="outlined" onClick={closeSA}>{strings.cancel}</Button>
                            </Grid>
                            <Grid item xs={8} sx={{ marginTop: '10px' }}>
                                <Box justifyContent='flex-end' sx={{ display: 'flex' }}>
                                    {(editedSA.idx !== undefined) ? <Button variant="contained" color="error" onClick={handleDeleteSA}>{strings.deletebutton}</Button> : ''}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item lg={10} sm={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    {CharacterRibbon(character)}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} lg={3} sx={adventureEntryProp}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} data-target='date'>
                                                <DateTimePicker
                                                    onChange={handleDateChange}
                                                    label={strings.date}
                                                    value={logAdventureEntry.date}
                                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} lg={2} sx={adventureEntryProp}>
                                            <Box>
                                                <FormControl fullWidth disabled={typeLocked}>
                                                    <InputLabel id='entry-type-selection'>{strings.entrytype}</InputLabel>
                                                    <Select value={selectedType} label="Log Type" labelId='entry-type-selection'
                                                        onChange={handleTypeChange}>
                                                        <MenuItem value='adventure'>{strings.adventure}</MenuItem>
                                                        <MenuItem value='dmreward'>{strings.dmreward}</MenuItem>
                                                        <MenuItem value='purchase'>{strings.purchase}</MenuItem>
                                                        <MenuItem value='trade'>{strings.trade}</MenuItem>
                                                        <MenuItem value='dtspend'>{strings.dtspent}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} display={showAdventure}>
                                            {adventure(logAdventureEntry, changeAttr)}
                                        </Grid>
                                        <Grid item xs={12} display={showDTSpend}>
                                            {dtspend(logAdventureEntry, changeAttr)}
                                        </Grid>
                                        <Grid item xs={12} display={showPurchase}>
                                            {purchase(logAdventureEntry, changeAttr)}
                                        </Grid>
                                        <Grid item xs={12} display={showDmLogs}>
                                            {dmLog(craftingTokens, logAdventureEntry, changeTokensWithCheck, invalidTokens)}
                                        </Grid>
                                        <Grid item xs={12} display={showTrade}>
                                            {trade(character, selectedItemTrade, handleOMTradeChange, logAdventureEntry, changeEditedSA, changeAttr)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} sx={{ marginTop: '8px' }}>
                                    <Box justifyContent='flex-start' sx={{ display: 'flex' }}>
                                        <Button variant="contained" onClick={onClickSave} disabled={hasError}>{strings.save}</Button>
                                        <LinkContainer to={`/infos/${character._id}`} sx={{ marginLeft: 1 }}>
                                            <Button variant="outlined">{strings.cancel}</Button>
                                        </LinkContainer>
                                    </Box>
                                </Grid>
                                <Grid item xs={8} sx={{ marginTop: '8px' }}>
                                    <Box justifyContent='flex-end' sx={{ display: 'flex' }}>
                                        <Button variant="contained" color="error" disabled={logAdventureEntry._id === undefined} onClick={() => { setOpenWarningAlert(true) }} >{strings.deletebutton}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} sm={0} sx={{ display: { lg: 'block', sm: 'none', xs: 'none' } }}><Image src={character.url} fit='scale-down' height='200px' /></Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={4} sx={{ marginTop: 1 }}>
                    <Typography variant='h5'>
                        {strings.magicitems}
                    </Typography>
                    <Grid container sx={{ marginTop: 1, width: '100%' }} fullWidth>
                        <Grid item xs={12} sx={{ marginLeft: 1, marginRight: 1 }}>
                            <Button fullWidth onClick={handleModalMI} variant='outlined' startIcon={<AddCircleOutlineIcon />}>{strings.addentry}</Button>
                        </Grid>
                        {(magicitems ?? []).map((mi) => {
                            mi.idx = idxMagicItems++
                            return (
                                <Grid item xs={12} fullWidth>
                                    <Paper sx={{ margin: 1, backgroundColor: 'background.paper' }}>
                                        <Grid container>
                                            <Grid item xs={10} sx={{ margin: 'auto' }}>
                                                <ItemEntry title={mi.name} desc={strings[mi.rarity]} />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box className='clickable cartouche hoverClickHint' onClick={handleModalMI} data-objid={mi.idx}
                                                    sx={{ marginRight: '1em' }}>
                                                    <EditIcon fontSize='small' sx={unclickable} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} sx={{ marginTop: 1 }}>
                    <Typography variant='h5'>
                        {strings.consumables}
                    </Typography>
                    <Grid container sx={{ marginTop: 1, width: '100%' }}>
                        <Grid item xs={12} sx={{ marginLeft: 1, marginRight: 1 }}>
                            <Button fullWidth onClick={handleModalCo} variant='outlined' startIcon={<AddCircleOutlineIcon />}>{strings.addentry}</Button>
                        </Grid>
                        {(consumables ?? []).map((co) => {
                            co.idx = idxConsumables++
                            return (
                                <Grid item xs={12} fullWidth>
                                    <Paper sx={{ margin: 1, backgroundColor: 'background.paper' }}>
                                        <Grid container>
                                            <Grid item xs={10} sx={{ margin: 'auto' }}>
                                                <ItemEntry title={co.name} desc={`${strings.quantity} : ${co.qty}`} />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box className='clickable cartouche hoverClickHint' onClick={handleModalCo} data-objid={co.idx}
                                                    sx={{ marginRight: '1em' }}>
                                                    <EditIcon fontSize='small' sx={unclickable} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>)
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} lg={4} sx={{ marginTop: 1 }}>
                    <Typography variant='h5'>
                        {strings.storyawards}
                    </Typography>
                    <Grid container sx={{ marginTop: 1, width: '100%' }}>
                        <Grid item xs={12} sx={{ marginLeft: 1, marginRight: 1 }}>
                            <Button fullWidth onClick={handleModalSA} variant='outlined' startIcon={<AddCircleOutlineIcon />}>{strings.addentry}</Button>
                        </Grid>
                        <Grid item sx={{ height: '300px', width: '100%' }}>
                            <Scrollbar>
                                {(storyawards ?? []).map((sa) => {
                                    sa.idx = idxStoryAwards++
                                    return (
                                        <Grid item xs={12} fullWidth>
                                            <Paper className="cartouche" sx={{ margin: 1, backgroundColor: 'background.paper' }}>
                                                <Grid container>
                                                    <Grid item xs={10} sx={{ margin: 'auto' }}>
                                                        <ItemEntry title={sa.name} desc={sa.desc} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Box className='clickable cartouche hoverClickHint' onClick={handleModalSA} data-objid={sa.idx}
                                                            sx={{ marginRight: '1em' }}>
                                                            <EditIcon fontSize='small' sx={unclickable} />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    )
                                })}
                            </Scrollbar>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ErrorBoundary>
    )
}

function trade(character, selectedItemTrade, handleOMTradeChange, logAdventureEntry, changeEditedSA, changeAttr) {
    return <Grid container>
        <Grid item xs={12} lg={10} sx={adventureEntryProp}>
            {character.magicitems && <Box>
                <FormControl fullWidth>
                    <InputLabel id='entry-om-selection'>{strings.magicitem}</InputLabel>
                    <Select value={selectedItemTrade} label="Log Type" labelId='entry-om-selection'
                        onChange={handleOMTradeChange}>
                        {character.magicitems ? character.magicitems.map((mi) => {
                            return <MenuItem value={mi._id}>{mi.name}</MenuItem>;
                        }) : ''}
                    </Select>
                </FormControl>
            </Box>}
        </Grid>
        <Grid item xs={12} lg={2} sx={adventureEntryProp}>
            <TextField type='number' label={strings.downtime} value={logAdventureEntry.dtgain} fullWidth onChange={changeAttr}
                inputProps={{ 'data-target': 'dtgain', inputMode: 'numeric' }} />
        </Grid>
        <Grid item xs={12} sx={adventureEntryProp}>
            <TextField fullWidth label={strings.desc} multiline rows={4} value={logAdventureEntry.notes} onChange={changeAttr} inputProps={{ 'data-target': 'notes' }} />
        </Grid>
    </Grid>;
}

function dmLog(craftingTokens, logAdventureEntry, changeTokensWithCheck, invalidTokens) {
    return <Grid container>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <Typography variant='h6'>{strings.availabletokens} : {craftingTokens}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <TextField type='number' label={strings.nbtoken} value={logAdventureEntry.tokenused} onChange={changeTokensWithCheck} error={invalidTokens}
                inputProps={{ 'data-target': 'tokenused', inputMode: 'numeric', pattern: '[0-9]*' }} />
        </Grid>
    </Grid>;
}

function purchase(logAdventureEntry, changeAttr) {
    return <Grid container>
        <Grid item xs={12} sm={6} lg={2} sx={adventureEntryProp}>
            <TextField label={strings.gold} value={logAdventureEntry.goldgain} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'goldgain' }} />
        </Grid>
        <Grid item xs={12} sm={6} lg={2} sx={adventureEntryProp}>
            <TextField type='number' label={strings.downtime} value={logAdventureEntry.dtgain} fullWidth onChange={changeAttr}
                inputProps={{ 'data-target': 'dtgain', inputMode: 'numeric', pattern: '[0-9]*' }} />
        </Grid>
        <Grid item xs={12} sx={adventureEntryProp}>
            <TextField label={strings.notes} value={logAdventureEntry.notes} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'notes' }} multiline rows={4} />
        </Grid>
    </Grid>;
}

function dtspend(logAdventureEntry, changeAttr) {
    return <Grid container>
        <Grid item xs={12} lg={1} sx={adventureEntryProp}>
            <TextField type='number' label={strings.downtime} value={logAdventureEntry.dtgain} fullWidth onChange={changeAttr}
                inputProps={{ 'data-target': 'dtgain', inputMode: 'numeric', pattern: '[0-9]*' }} />
        </Grid>
        <Grid item xs={12} lg={1} sx={adventureEntryProp}>
            <TextField label={strings.lvlgain} value={logAdventureEntry.lvlgain} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'lvlgain' }} />
        </Grid>
        <Grid item xs={12} sx={adventureEntryProp}>
            <TextField label={strings.notes} value={logAdventureEntry.notes} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'notes' }} multiline rows={4} />
        </Grid>
    </Grid>;
}

function adventure(logAdventureEntry, changeAttr) {
    return <Grid container>
        <Grid item xs={12} lg={5} sx={adventureEntryProp}>
            <TextField label={strings.code} value={logAdventureEntry.code} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'code' }} />
        </Grid>
        <Grid item xs={12} lg={4} sx={adventureEntryProp}>
            <TextField label={strings.dm} value={logAdventureEntry.dm} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'dm' }} />
        </Grid>
        <Grid item xs={12} lg={1} sx={adventureEntryProp}>
            <TextField label={strings.lvlgain} value={logAdventureEntry.lvlgain} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'lvlgain' }} />
        </Grid>
        <Grid item xs={12} lg={1} sx={adventureEntryProp}>
            <TextField label={strings.gold} value={logAdventureEntry.goldgain} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'goldgain' }} />
        </Grid>
        <Grid item xs={12} lg={1} sx={adventureEntryProp}>
            <TextField type='number' label={strings.downtime} value={logAdventureEntry.dtgain} fullWidth onChange={changeAttr}
                inputProps={{ 'data-target': 'dtgain', inputMode: 'numeric', pattern: '[0-9]*' }} />
        </Grid>
        <Grid item xs={12} sx={adventureEntryProp}>
            <TextField label={strings.notes} value={logAdventureEntry.notes} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'notes' }} multiline rows={4} />
        </Grid>
    </Grid>;
}
