const containerStyle = {
    backgroundColor: 'background.default', 
    opacity: '90%', 
    width: '80%', 
    margin: 'auto'
}

const adventureEntryProp = {
    marginTop: 1
}

export {containerStyle, adventureEntryProp}