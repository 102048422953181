import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import React from 'react';
import { containerStyle } from './styles/common';

export function ErrorMessage() {
    return (<Grid container className='mainContainer' sx={containerStyle}>
        <Grid item xs={12}>
            <Typography variant="h6">An error occured</Typography>
        </Grid>

        <Grid item xs={12}>
            <Typography variant="body1">Sorry</Typography>
        </Grid>
    </Grid>);
}
