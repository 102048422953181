import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSnackBarOptions } from "../common/common";
import { getUserDMLog, getUserPCs, setDMLog } from "../repository/repository";
import { ErrorMessage } from "./ErrorMessage";
import strings from "./localization";
import { adventureEntryProp, containerStyle } from "./styles/common";

export default function CreateEditDMLog() {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const params = useParams()

    var tmp = {
        date: Date.now(),
        code: '',
        session: '',
        type: '',
        charid: '',
        dateassigned: Date.now(),
        lvlup: false,
        notes: '',
        setting: 'cc'
    }

    const logId = params['id']

    const [contentLoaded, setContentLoaded] = useState(false)
    const [pageError, setPageError] = useState(false)
    const [characters, setCharacters] = useState([])
    const [charactersVisible, setCharactersVisible] = useState('hidden')
    const [log, setLog] = useState(tmp)
    const [canView, setCanView] = useState(false)
    const [showSecured, setShowSecured] = useState('hidden')
    const [showUnsecured, setShowUnsecured] = useState('hidden')
    const [assignedType, setAssignedType] = useState('none')

    const [lvlupLocked, setLvlupLocked] = useState(true)
    const [lvlup, setLvlup] = useState(false)
    const [selectedCharacter, setSelectedCharacter] = useState('none')

    const user = JSON.parse(sessionStorage.getItem('user'))

    useEffect(() => {
        setLvlupLocked(selectedCharacter === undefined || selectedCharacter === '')
    }, [selectedCharacter])

    useEffect(() => {
        if (contentLoaded) return

        getUserPCs(user._id)
            .then((data) => {
                const ccPcs = data.filter(d => d.setting === 'cc')
                setCharacters(ccPcs)
            })

        if (logId) {
            const user = JSON.parse(sessionStorage.getItem('user'))
            const key = sessionStorage.getItem('key')

            getUserDMLog(key, user._id, logId)
                .then((data) => {
                    data.entry.setting = 'cc'
                    setLog(data.entry)
                    setContentLoaded(true)
                    setCanView(true)
                    setAssignedType(data.entry.type)
                    setShowSecured('visible')
                    setSelectedCharacter(data.entry.charid)
                    setLvlup(data.entry.lvlup)
                })
                .catch((error) => {
                    enqueueSnackbar(error, getSnackBarOptions('error'))
                    setShowUnsecured('visible')
                    setPageError(true)
                })
        } else {
            setContentLoaded(true)
            setShowSecured('visible')
            setCanView(true)
        }
    }, [logId, enqueueSnackbar, contentLoaded, user._id])

    useEffect(() => {
        if (assignedType === 'adventurereward') {
            setCharactersVisible('visible')
        } else {
            setCharactersVisible('hidden')
            setSelectedCharacter(undefined)
            setLvlupLocked(true)
        }
    }, [assignedType])

    const changeAssignType = (event) => {
        setAssignedType(event.target.value)

        setLog(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.type = event.target.value
            return buffer
        })
    }

    const handleDateChange = (value, keyboardInputValue) => {

        if (value === null) {
            return
        }

        setLog(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.date = value.$d
            return buffer
        })
    }

    const changeAttr = (event) => {
        const attr = event.target.dataset.target

        setLog(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const changeCharLogDate = (value, keyboardInputValue) => {

        if (value === null) {
            return
        }

        setLog(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.dateassigned = value.$d
            return buffer
        })
    }

    const onClickSave = (event) => {
        const user = JSON.parse(sessionStorage.getItem('user'))
        const key = sessionStorage.getItem('key')

        var buffer = Object.assign({}, log)

        if (buffer.type !== 'adventurereward') {
            buffer.lvlup = false
            buffer.charid = undefined
        } else {
            buffer.lvlup = lvlup
            buffer.charid = selectedCharacter
        }

        setDMLog(buffer, user._id, key)
            .then((data) => {
                if (data.status === 'OK') {
                    const options = getSnackBarOptions('success')
                    options.autoHideDuration = 1000

                    options.onExited = () => {
                        navigate('/dmlogs');
                    }
                    enqueueSnackbar(strings.savesuccess, options)
                } else {
                    enqueueSnackbar(data, getSnackBarOptions('error'))
                    setPageError(true)
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, getSnackBarOptions('error'))
                setPageError(true)
            })
    }

    const changeCharacter = (event) => {
        setLvlupLocked(false)

        setLvlup(true)
        setSelectedCharacter(event.target.value)
    }

    const handleChangeLvlUp = (event) => {
        setLvlup(event.target.checked)
        setLog(prevState => {
            prevState.lvlup = event.target.checked
            return prevState
        })
    }

    const changeSettings = (event) => {
        setLog(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.setting = event.target.value
            return buffer
        })
    }

    if (pageError) {
        return <ErrorMessage />
    }

    if (!contentLoaded) {
        return <CircularProgress sx={{ color: 'Scrollbar' }} />;

    }

    return (
        <Box>
            <Grid container sx={containerStyle} visibility={showSecured}>
                <Grid item xs={12}>
                    <h2>{strings.gameinfos}</h2>
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={12} lg={3} sx={adventureEntryProp}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} data-target='date'>
                        <DateTimePicker
                            onChange={handleDateChange}
                            value={log.date}
                            label={strings.date}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={2} sx={adventureEntryProp}>
                    <FormControl fullWidth>
                        <InputLabel id='setting-type-selection'>{strings.setting}</InputLabel>
                        <Select label="Log Type" value={log.setting} labelId='entry-type-selection' onChange={changeSettings}>
                            {/* <MenuItem value='al'>{strings.al}</MenuItem> */}
                            <MenuItem value='cc' selected={true}>{strings.cc}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4} sx={adventureEntryProp}>
                    <TextField label={strings.code} value={log.code} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'code' }} />
                </Grid>
                <Grid item xs={12} lg={1} sx={adventureEntryProp}>
                    <TextField type={"number"} label={strings.session} value={log.session} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'session' }} />
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={10} sx={adventureEntryProp}>
                    <TextField label={strings.notes} value={log.notes} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'notes' }} multiline rows={4} />
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={12}>
                    <h2>{strings.reward}</h2>
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={12} lg={3} sx={adventureEntryProp}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} data-target='date'>
                        <DateTimePicker
                            label={strings.dateassigned} value={log.assigndate} onChange={changeCharLogDate}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} lg={2} sx={adventureEntryProp} disabled={true} >
                    <Box>
                        <FormControl fullWidth >
                            <InputLabel id='entry-type-selection'>{strings.entrytype}</InputLabel>
                            <Select label="Log Type" value={assignedType} labelId='entry-type-selection' onChange={changeAssignType}>
                                <MenuItem value='adventurereward'>{strings.adventurereward}</MenuItem>
                                <MenuItem value='crafttoken'>{strings.crafttoken}</MenuItem>
                                <MenuItem value='none'>{strings.none}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} lg={5} sx={adventureEntryProp} visibility={charactersVisible}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs={12} lg={7}>
                            <FormControl fullWidth>
                                <InputLabel id='entry-type-selection'>{strings.character}</InputLabel>
                                <Select label="Log Type" value={selectedCharacter} labelId='entry-type-selection' onChange={changeCharacter}>
                                    {characters.map(c => {
                                        return (
                                            <MenuItem value={c._id}>{c.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <FormControlLabel disabled={lvlupLocked} control={<Checkbox checked={lvlup} onChange={handleChangeLvlUp} />} label={strings.takeLevelUp} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={0} lg={1}></Grid>
                <Grid item xs={12}>
                    <Button variant="contained" sx={{ marginTop: '8px', marginBottom: '8px' }} onClick={onClickSave} disabled={!canView}>{strings.save}</Button>
                    <Button variant="outlined" sx={{ marginTop: '8px', marginBottom: '8px', marginLeft: '8px' }} onClick={() => {
                        navigate('/dmlogs');
                    }} >{strings.cancel}</Button>
                </Grid>
            </Grid>
            <Grid container visibility={showUnsecured} sx={containerStyle}>
                <Grid item xs={12}>
                    <h2>{strings.cannotshowcontent}</h2>
                </Grid>
            </Grid>
        </Box>
    )
}