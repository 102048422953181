import { Grid, Tooltip, Typography } from "@mui/material";

export default function ItemEntry(props) {
    return (
        <Grid container sx={{ width: '100%', height: '100%' }}>
            <Grid item xs={12}>
                <Tooltip title={props.title}>
                    <Typography noWrap={true} sx={{ marginLeft: '0.5em', marginRight: '0.5em', fontWeight: 'bold' }}>
                        {props.title}
                    </Typography>
                </Tooltip>
            </Grid>
            <Grid item xs={12}>
                {(props.showDescTooltip ?? false) && <Tooltip title={props.desc}>
                    <Typography noWrap={true} variant="subtitle2">
                        {props.desc}
                    </Typography>
                </Tooltip>
                }
                {!props.showDescTooltip && <Typography noWrap={true} variant="subtitle2" sx={{ marginLeft: '1em', marginRight: '1em' }}>
                    {props.desc}
                </Typography>}
            </Grid>
        </Grid>
    )
}