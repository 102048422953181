import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import Image from "mui-image";
import { useEffect, useState } from "react";
import Strings from './localization'
import { setCharacter, getPC, deleteCharacter } from "../repository/repository";
import { containerStyle } from "./styles/common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import strings from "./localization";
import { getSnackBarOptions } from "../common/common";
import { Box } from "@mui/system";
import WarningDialog from "../warningDialog";
import { ErrorMessage } from "./ErrorMessage";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function CreateEditCharacter() {

    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const source = searchParams.get('source');
    const navigate = useNavigate();
    var tmpChar = { name: '', pcClass: '', race: '', url: '', setting: '', startinggold: 0 }

    const [contentLoaded, setContentLoaded] = useState(false)
    const [pageError, setPageError] = useState(false)

    const [pcId, setPcId] = useState(params['pcid'])
    const [pc, setPc] = useState(tmpChar)
    const [nameError, setNameError] = useState(false)
    const [classError, setClassError] = useState(false)
    const [raceError, setRaceError] = useState(false)
    const [settingError, setSettingError] = useState(false)
    const [showInterface, setShowInterface] = useState(true)
    const [openWarningAlert, setOpenWarningAlert] = useState(false)


    useEffect(() => {
        if (pcId === undefined) {
            setContentLoaded(true)
            return
        }
        if (!contentLoaded) {
            getPC(pcId)
                .then((json) => {
                    if (json.startinggold === undefined) {
                        json.startinggold = 0
                    }
                    setPc(json)
                    setContentLoaded(true)
                })
        }
    }, [pcId])

    const changeAttr = (event) => {
        const attr = event.target.dataset.target

        setPc(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const save = (event) => {
        var errorMsg = ''

        if (pc.name.trim() === '') {
            setNameError(true)
            errorMsg += `\n${Strings.name}`
        }

        if (pc.pcClass.trim() === '') {
            setClassError(true)
            errorMsg += `\n${Strings.class}`
        }

        if (pc.race.trim() === '') {
            setRaceError(true)
            errorMsg += `\n${Strings.race}`
        }

        if (pc.setting.trim() === '') {
            setSettingError(true)
            errorMsg += `\n${Strings.setting}`
        }

        if (errorMsg.length > 0) {
            enqueueSnackbar(`${Strings.cannotbeempty} ${errorMsg}`, getSnackBarOptions('error'))
        } else {
            const key = sessionStorage.getItem('key')
            const user = JSON.parse(sessionStorage.getItem('user'))
            try {
                setCharacter(pc, key, user._id)
                    .then((json) => {
                        const data = JSON.parse(json)
                        if (data.status === 'KO') {
                            enqueueSnackbar(data.msg, getSnackBarOptions('error'))
                        } else {
                            setPc(data.char)
                            setPcId(data.char._id)

                            const options = getSnackBarOptions('success')
                            options.autoHideDuration = 500

                            options.onExited = () => {
                                navigate('/');
                            }

                            enqueueSnackbar(strings.savesuccess, options)
                        }
                    })
                    .catch((error) => {
                        setShowInterface(false)
                        enqueueSnackbar(error, getSnackBarOptions('error'))
                        setPageError(true)
                    })
            } catch (err) {
                setShowInterface(false)
                enqueueSnackbar(err, getSnackBarOptions('error'))
                setPageError(true)
            }
        }
    }

    const handleCloseWarningAlert = (event) => {
        setOpenWarningAlert(false)
    }

    const handleDeleteCharacter = (event) => {
        try {
            setOpenWarningAlert(false)

            const user = JSON.parse(sessionStorage.getItem('user'))
            const key = sessionStorage.getItem('key')

            deleteCharacter(pc._id, user._id, key)
                .then((json) => {
                    const options = getSnackBarOptions('success')
                    options.autoHideDuration = 500

                    options.onExited = () => {
                        navigate(`/`);
                    }

                    enqueueSnackbar(strings.deletesuccess, options)
                })
        } catch (err) {
            enqueueSnackbar(err, getSnackBarOptions('error'))
            setPageError(true)
        }
    }

    const changeSettings = (event) => {
        setPc(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.setting = event.target.value
            return buffer
        })
    }

    const handleDateChange = (value) => {
        if (value === null) {
            return
        }
        setPc(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer.createdate = value.$d
            return buffer
        })
        
    }

    const getCancelButton = () => {
        if (source === 'charinfos' && pcId !== undefined) {
            return <Button variant="outlined" onClick={() => { navigate(`/infos/${pcId}`) }} sx={{ marginLeft: 1 }}>{strings.cancel}</Button>
        }
        else {
            return <Button variant="outlined" onClick={() => { navigate('/') }} sx={{ marginLeft: 1 }}>{strings.cancel}</Button>
        }
    }

    if (pageError) {
        return <ErrorMessage />
    }

    if (!contentLoaded) {
        return <CircularProgress sx={{ color: 'Scrollbar' }} />;
    }

    return (
        <Box>
            <WarningDialog message={`${strings.deletewarningnoquestionmark} ${pc.name} ?`} message2={strings.contentlostwarning}
                open={openWarningAlert} handleClose={handleCloseWarningAlert}
                handleYes={handleDeleteCharacter} handleCancel={handleCloseWarningAlert} />

            {showInterface ? <Grid container spacing={4} sx={containerStyle}>
                {true}
                <Grid item lg={6} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id='setting-type-selection'>{strings.setting}</InputLabel>
                                <Select label="Log Type" value={pc.setting} labelId='entry-type-selection' onChange={changeSettings}>
                                    <MenuItem value='al'>{strings.al}</MenuItem>
                                    <MenuItem value='cc'>{strings.cc}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} data-target='date'>
                                <DateTimePicker
                                    onChange={handleDateChange}
                                    value={pc.createdate}
                                    label={strings.createdate}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField xs={12}
                                id="CharName"
                                label={Strings.name}
                                value={pc.name}
                                onChange={changeAttr}
                                inputProps={{ 'data-target': 'name' }}
                                fullWidth
                                error={nameError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField xs={12}
                                id="ClassName"
                                label={Strings.class}
                                value={pc.pcClass}
                                onChange={changeAttr}
                                inputProps={{ 'data-target': 'pcClass' }}
                                fullWidth
                                error={classError}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <TextField xs={12}
                                id="RaceName"
                                label={strings.race}
                                value={pc.race}
                                onChange={changeAttr}
                                inputProps={{ 'data-target': 'race' }}
                                fullWidth
                                error={raceError}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <TextField xs={12}
                                id="StartingGold"
                                label={strings.startingGold}
                                value={pc.startinggold}
                                onChange={changeAttr}
                                inputProps={{ 'data-target': 'startinggold', inputMode: 'numeric', pattern: '[0-9]*' }}
                                fullWidth
                                error={raceError}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="imgUrl"
                                label="Avatar Url"
                                value={pc.url}
                                onChange={changeAttr}
                                inputProps={{ 'data-target': 'url' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '10px' }}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <Button variant="contained" onClick={save}>{strings.save}</Button>
                                    {getCancelButton()}
                                </Grid>
                                <Grid item xs={8} sx={{ marginTop: '10px' }}>
                                    <Box justifyContent='flex-end' sx={{ display: 'flex' }}>
                                        {(pc._id !== undefined) ? <Button variant="contained" color="error" onClick={() => { setOpenWarningAlert(true) }}>{strings.deletebutton}</Button> : ''}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item lg={6} xs={12} maxHeight='800px'>
                    <Grid container spacing={2} paddingTop={2} paddingLeft={2}>
                        <Box className="show-img" style={{ maxHeight: 'inherit' }}>
                            <Image src={pc.url} fit='contain' height='750px' />
                            <Box className="img-control">
                                <Button component="label">
                                    <input hidden accept="image/*" multiple type="file" />
                                    <ImageIcon sx={{ fontSize: 64 }} />
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid> : ''}
        </Box>
    )
}