import Grid from '@mui/material/Grid';
import { Box, Button, Drawer, IconButton, List, ListItem, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import strings from './components/localization';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { getDiscordUrl } from './common/common';
import { LinkContainer } from 'react-router-bootstrap';

export function MobileMenu(props) {
  return (<Toolbar>
    <Box component="nav" sx={{ mr: 2 }}>
      <Drawer anchor='left' open={props.mobileOpen} onClose={props.handleDrawerToggle}>
        <Box sx={{ width: "1/5" }}>
          <List>
            <ListItem id='menu-home'><LinkContainer to='/'><Button>{strings.home}</Button></LinkContainer></ListItem>
            <ListItem id='menu-create'><LinkContainer to='create'><Button>{strings.create}</Button></LinkContainer></ListItem>
            <ListItem id='menu-create'><LinkContainer to='dmlogs'><Button>{strings.dmlogs}</Button></LinkContainer></ListItem>
          </List>
        </Box>
      </Drawer>
    </Box>
    <IconButton size="medium" edge="start" color="inherit" aria-label="menu" onClick={props.handleDrawerToggle}
      sx={{ mr: 2 }} id="main-menu-icon" aria-haspopup="true" aria-expanded={props.open ? 'true' : undefined} variant="outlined">
      <MenuIcon />
    </IconButton>
    <Box sx={{ flexGrow: 1 }} />
    {props.user !== null && props.user !== undefined ? <Grid container alignItems={"center"} sx={{ width: 1 / 5 }}>
      <Grid item md={10} sx={{
        display: {
          md: 'block',
          xs: 'none'
        }
      }}>
        <Typography variant="h6" component="div" sx={{
          textAlign: 'right',
          marginRight: 1 / 5
        }}> {props.user.username} </Typography>
      </Grid>
      <Grid item md={2} xs={12}>
        <Avatar alt={props.user.username} src={`https://cdn.discordapp.com/avatars/${props.user.id}/${props.user.avatar}`} sx={{
          margin: 'auto'
        }} />
      </Grid>
    </Grid> : ''}
    <Box sx={{
      flexGrow: 1
    }} />
    <Box sx={{
      display: {
        xs: 'none',
        md: 'flex'
      }
    }}>
      <Select value={props.lang} sx={{
        color: 'white'
      }} onChange={props.handleLangChange}>
        <MenuItem value={'en'}>En</MenuItem>
        <MenuItem value={'fr'}>Fr</MenuItem>
      </Select>
    </Box>
    <IconButton sx={{ ml: 1 }} onClick={props.toggleColorMode} color="inherit">
      {props.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
    {props.user && <Button color="inherit" onClick={props.logout}>{strings.logout}</Button>}
    {!props.user && <Button color="inherit" href={getDiscordUrl()}>{strings.login}</Button>}
  </Toolbar>);
}
