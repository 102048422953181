const getDiscordUrl = () => {
    const redirectUrl = encodeURIComponent(process.env.REACT_APP_HOME_URL)
    const clientId = process.env.REACT_APP_CLIENT_ID
    return `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}%2Flogin&response_type=code&scope=identify`
}

const getSnackBarOptions = (variant) => {
    return { autoHideDuration: 3000, variant: variant, anchorOrigin: { horizontal: 'center', vertical: 'top' } }
}

export { getDiscordUrl, getSnackBarOptions };