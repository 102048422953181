import testDataUserPcs from './testData/pcs'
import testDataLogs from './testData/logs'

const apiurl = process.env.REACT_APP_API_URL

var fakeCollections = {
    'userPcs': testDataUserPcs,
    'PCLogs': testDataLogs
}

async function canConnecToApi() {
    const response = await fetch(`${apiurl}/testconnection`)
    manageHttpStatus(response)
    return response
}

async function getUserDMLogs(key, id) {
    const response = await fetch(`${apiurl}/users/${id}/dmlogs`, getFetchHeader(id, key))
    manageHttpStatus(response)
    const data = await response.json()

    return data
}

async function getUser(key, id) {
    const response = await fetch(`${apiurl}/users/${id}`, getFetchHeader(id, key))
    manageHttpStatus(response)
    const data = await response.json()

    return data
}

function getFetchHeader(id, key) {
    return {
        method: 'get', headers: getHeader(id, key)
    }
}

function getHeader(id, key) {
    return {
        'Content-Type': 'application/json',
        'user-id': id,
        'app-key': key
    }
}

function getApiurl() {
    return apiurl
}

async function getUserDMLog(key, id, logid) {
    const response = await fetch(`${apiurl}/users/${id}/dmlogs/${logid}`, {
        method: 'get', headers: getHeader(id, key)
    })
    await manageHttpStatus(response)
    const data = await response.json()

    return data
}

async function manageHttpStatus(response) {
    if (response.status !== 200) {
        if (response.status >= 500) {
            const msg = `Error reaching the server : ${response.statusText}`
            throw msg
        }
        if (response.status >= 400) {
            if (response.message) {
                throw response.message
            }
            throw response.statusText
        }
    }
}

async function setCharacter(character, key, id) {
    try {
        const response = await fetch(`${apiurl}/characters`, {
            method: 'post', headers: getHeader(id, key), body: JSON.stringify({
                character: character,
                id: id
            })
        })
        await manageHttpStatus(response)

        const data = await response.json()
        return data
    } catch (err) {
        console.log(err)
    }
}

async function deleteCharacter(pcId, id, key) {
    try {
        const response = await fetch(`${apiurl}/characters/${pcId}`, {
            method: 'DELETE', headers: getHeader(id, key)
        })
        await manageHttpStatus(response)

        const data = await response.json()
        return data
    } catch (err) {
        console.log(err)
        throw err
    }
}

async function sendCharacter(selectedFile, pcid, id, key) {
    const apiurl = getApiurl()

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('uid', id);
    formData.append('key', key);

    var headers = getHeader(id, key)
    headers["Content-Type"] = "multipart/form-data"

    const response = await fetch(`${apiurl}/characters/${pcid}/overwrite`, {
        method: 'post',
        body: formData
    })

    await manageHttpStatus(response)
    const data = await response.json()
    return data
}

async function sendDmLogs(selectedFile, id, key) {

    const apiurl = getApiurl()

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('uid', id);
    formData.append('key', key);

    const response = await fetch(`${apiurl}/dmlogs/overwrite`, {
        method: 'post',
        body: formData
    })

    await manageHttpStatus(response)
    const data = await response.json()
    return data
}

async function getUserPCs(userId) {
    const response = await fetch(`${apiurl}/users/${userId}/characters`)
    await manageHttpStatus(response)
    const data = await response.json()
    var content = data

    return content
}

async function saveLogEntry(pcid, entry, id, key) {
    const response = await fetch(`${apiurl}/characters/${pcid}/log`,
        {
            method: 'post', headers: getHeader(id, key), body: JSON.stringify({
                entry: entry
            })
        })
    await manageHttpStatus(response)
    const data = await response.json()
    return data
}

async function deleteLogEntry(pcid, entryid, id, key) {
    const response = await fetch(`${apiurl}/characters/${pcid}/logs/${entryid}`,
        {
            method: 'DELETE', headers: getHeader(id, key)
        })
    await manageHttpStatus(response)
    const data = await response.json()
    return data
}

async function getPC(pcid, id, key) {
    const response = await fetch(`${apiurl}/characters/${pcid}`, { method: 'get', headers: getHeader(id, key) })
    const data = await response.json()
    var content = data
    return content
}

async function getLogs(pcId, logId) {
    const response = await fetch(`${apiurl}/characters/${pcId}/logs/${logId}`)
    await manageHttpStatus(response)
    const data = await response.json()
    return data
}

async function setDMLog(entry, id, key) {
    const response = await fetch(`${apiurl}/users/${id}/dmlog`,
        {
            method: 'post', headers: getHeader(id, key), body: JSON.stringify({
                id: id,
                key: key,
                entry: entry
            })
        })
    await manageHttpStatus(response)
    const data = await response.json()
    return data
}

async function saveFaction(faction, charId, id, key) {
    const response = await fetch(`${apiurl}/characters/${charId}/faction`, {
        method: 'post', headers: getHeader(id, key), body: JSON.stringify({
            faction: faction
        })
    })
    await manageHttpStatus(response)
    const data = await response.json()
    return JSON.parse(data)
}

async function deleteFaction(faction, charId, id, key) {
    const response = await fetch(`${apiurl}/characters/${charId}/faction/${faction._id}`, {
        method: 'delete', headers: getHeader(id, key)
    })

    await manageHttpStatus(response)
    const data = await response.json()
    return JSON.parse(data)
}

async function uploadCharacter(file, charId, id, key) {
    var header = getHeader(id, key)
    header['Content-Type'] = 'multipart/form-data'

    const response = await fetch(`${apiurl}/characters/${charId}/overwrite`, {
        method: 'post', headers: header
    }
    )
    await manageHttpStatus(response)
    const data = await response.json()
    return JSON.parse(data)
}

export {
    getPC, getLogs, getUserPCs, setCharacter, saveLogEntry, getUserDMLogs, getUserDMLog, setDMLog, getUser, saveFaction,
    deleteFaction, canConnecToApi, deleteLogEntry, deleteCharacter, uploadCharacter, getHeader, getApiurl, manageHttpStatus,
    sendDmLogs, sendCharacter
};