import { Card, CardHeader, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PCCard from "./pcCard";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from "react-router-dom";
import { getUserPCs } from "../repository/repository";
import { useSnackbar } from "notistack";
import { getSnackBarOptions } from "../common/common";
import { Box } from "@mui/system";
import strings from "./localization";
import { ErrorMessage } from "./ErrorMessage";

export function CharactersList(props) {
    const { enqueueSnackbar } = useSnackbar();

    const [pcs, setPcs] = useState([])
    const [charsLoaded, setCharsLoaded] = useState(false)
    const [pageError, setPageError] = useState(false)
    var user = JSON.parse(sessionStorage.getItem('user'))

    useEffect(() => {
        if (charsLoaded) { return }
        if (user) {
            getUserPCs(user._id)
                .then((data) => {
                    setPcs(data)
                    setCharsLoaded(true)
                })
                .catch((error) => {
                    enqueueSnackbar(error, getSnackBarOptions('error'))
                    setPageError(true)
                })
        } else {
            enqueueSnackbar(strings.usernotfound, getSnackBarOptions('error'))
        }
    }, [user])

    if (pageError) {
        return <ErrorMessage/>
    }

    if (!charsLoaded) {
        return <CircularProgress sx={{ color: 'Scrollbar' }} />;
    }

    return (<Grid container spacing={4} width={4 / 5} margin={'auto'}>
        {(pcs ?? []).map(pc => <Grid item xl={3} lg={4} sm={6} xs={12}>
            <PCCard name={pc.name} pcClass={pc.pcClass} race={pc.race} lvl={pc.lvl} url={pc.url} pcid={pc._id} />
        </Grid>)}
        <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Card sx={{
                height: '340px',
                width: '1/3'
            }}>
                <CardHeader title='Create new Character' />
                <Box sx={{
                    margin: 1 / 5
                }} style={{
                    maxHeight: '190px',
                    width: 'auto'
                }}>
                    <Link to='/create'>
                        <Box className='clickable hoverClickHint' color='text.secondary' sx={{ maxHeight: '100px' }}>
                            <AddCircleIcon sx={{
                                maxHeight: '280px',
                                height: 1,
                                width: 1
                            }} />
                        </Box>
                    </Link>
                </Box>
            </Card>
        </Grid>
    </Grid>);
}
