import { useTheme } from "@emotion/react";
import { Button, Card, CardActions, CardHeader, Tooltip, Typography, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import Image from 'mui-image'
import { LinkContainer } from "react-router-bootstrap";
import strings from "./localization";

export default function PCCard(props) {
    const theme = useTheme();
    
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Card variant="outlined" sx={{ height: '340px', width: '1/3' }} onClick={props.click}>
            <Tooltip title={`${props.name}`}>
                <Box>
                    <CardHeader title={props.name} subheader={`${strings.level} ${props.lvl} ${props.race} ${props.pcClass}`}
                        classes={{
                            root: 'cardHeaderRoot',
                            title: 'cardHeadertitle',
                            content: 'cardHeaderRoot',
                            subheader: 'cardHeaderSubTitle'
                        }} />
                </Box>
            </Tooltip>
            <Image height={'200px'} src={props.url} fit='contain' />

            <CardActions >
                <LinkContainer to={`/infos/${props.pcid}`}>
                    <Button variant="contained" sx={{width: 1/2}}>
                        <Typography variant="button">
                            {isMediumScreen ? strings.infoslogs : strings.infoslogsshort }
                        </Typography>
                    </Button>
                </LinkContainer>
                <LinkContainer to={`/edit/${props.pcid}`} sx={{width: 1/2}}>
                    <Button>
                        <Typography variant="button">
                            {isLargeScreen ? strings.editcharacter : strings.editcharactershort }
                        </Typography>
                    </Button>
                </LinkContainer>
            </CardActions>
        </Card>
    )
}