import { Avatar, Grid, Link, Typography } from "@mui/material";
import React from "react";
import { CharactersList } from "./charactersList";
import { containerStyle } from "./styles/common";
import Strings from './localization'
import { getDiscordUrl } from "../common/common";


export default function Home(props) {
    var user = JSON.parse(sessionStorage.getItem('user'))

    if (user) {
        return (
            <CharactersList />
        )
    } else {
        return (
            <Grid container spacing={4} sx={containerStyle} alignItems="stretch" justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="h5" color="text.primary">
                        {Strings.notloggedin}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                    <Typography variant="body" color="text.primary">
                        {Strings.plzlogin}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                    <Link href={getDiscordUrl()}><Avatar alt='Discor' src="/img/discord-icon.png" sx={{ margin: 'auto' }} /></Link>
                </Grid>
            </Grid>
        )
    }
}