import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from "@mui/material";
import strings from "./components/localization";

export default function WarningDialog(props) {
    const fullScreen = useMediaQuery('(max-width:600px)');
    return (
        <Dialog id='alert-dialog'
            fullScreen={fullScreen}
            maxWidth={fullScreen ? 'xs' : 'md'}
            open={props.open} onClose={props.handleClose}>
            <DialogTitle id='alert-dialog-title'>{strings.warning}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description-l1">
                    {props.message}
                </DialogContentText>
                {
                    props.message2 ? <DialogContentText id="alert-dialog-description-l2" sx={{ marginTop: 1 }}>
                        {props.message2}
                    </DialogContentText> : ''
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleYes} variant="contained" color="error" sx={{ width: 1 / 2 }}>{strings.continue}</Button>
                <Button onClick={props.handleCancel}  sx={{ width: 1 / 2 }} autoFocus>
                    {strings.cancel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}