import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import Image from 'mui-image'
import Paper from '@mui/material/Paper';
import React, { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import strings from './localization'
import ItemEntry from './itemEntry';
import { Stack } from '@mui/system';
import { containerStyle } from './styles/common';
import { useParams } from 'react-router-dom';
import { CharacterRibbon } from './characterRibbon';
import { deleteFaction, getPC, saveFaction, sendCharacter } from '../repository/repository';
import { useSnackbar } from 'notistack';
import { getSnackBarOptions } from '../common/common';
import TextFieldLabel from './textFieldLabel';
import { LinkContainer } from 'react-router-bootstrap';
import WarningDialog from '../warningDialog';
import { ErrorMessage } from './ErrorMessage';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

const modalStyle = {
    minWidth: 400
}


export default function CharacterInfos() {
    const { enqueueSnackbar } = useSnackbar();
    const user = JSON.parse(sessionStorage.getItem('user'))

    const params = useParams()
    const pcId = params['pcid']

    const [contentLoaded, setContentLoaded] = useState(false)
    const [pageError, setPageError] = useState(false)

    const [openDialogInfos, setOpenDialogInfos] = useState(false);
    const [openWarningAlert, setOpenWarningAlert] = useState(false);
    const [openOverwriteWarningAlert, setOpenOverwriteWarningAlert] = useState(false);
    const [openDialogRedirect, setopenDialogRedirect] = useState(false)
    const [dialogTitle, setDialogTitle] = useState('Not Set');
    const [dialogType, setDialogType] = useState('Not Set');
    const [dialogContent, setDialogContent] = useState('Not Set');
    const [characterInfos, setCharacterInfos] = useState({ logs: [] })
    const [openFactionInfos, setOpenFactionInfos] = useState(false)
    const [currentFaction, setCurrentFaction] = useState({})
    const [editFaction, setEditFaction] = useState(false)
    const [creatingFaction, setCreatingFaction] = useState(false)
    const [editableCharacter, setEditableCharacter] = useState(false)
    const [factionHasErrors, setFactionHasErrors] = useState(true)
    const [SelectedFile, setSelectedFile] = useState(true)
    const [openMoreInfos, setOpenMoreInfos] = useState(false)
    const [moreInfosLog, setMoreInfosLog] = useState({})

    const handleOpenDetails = (event, title, type, content) => {
        setDialogTitle(title)
        setDialogType(type)
        setDialogContent(content)
        setOpenDialogInfos(true)
    };

    useEffect(() => {
        setFactionHasErrors(incorrectFactionData(currentFaction))
    }, [currentFaction])

    useEffect(() => {
        setPCInfos();
    }, [pcId, enqueueSnackbar])

    useEffect(() => {
        if (characterInfos) {
            setEditableCharacter(characterInfos.isOwned)
        } else {
            setEditableCharacter(false)
        }
    }, [characterInfos])

    useEffect(() => {
        if (openFactionInfos === false) {
            setCreatingFaction(false)
            setEditFaction(false)
            setCurrentFaction({})
        }
    }, [openFactionInfos])

    const handleEditFaction = (objid) => {
        const faction = characterInfos.factions.find(f => f._id === objid)
        setCurrentFaction(faction)
        setOpenFactionInfos(true)
    }

    const handleDeleteFaction = () => {
        if (currentFaction) {
            const key = sessionStorage.getItem('key')
            deleteFaction(currentFaction, characterInfos._id, user._id, key)
                .then((data) => {
                    if (data.status === 'OK') {
                        enqueueSnackbar(strings.savesuccess, getSnackBarOptions('success'))
                    } else {
                        enqueueSnackbar(data.msg, getSnackBarOptions('error'))
                    }
                }).catch((error) => {
                    enqueueSnackbar(error, getRowTitle('error'))
                    setPageError(true)
                })
                .finally(() => {
                    setOpenFactionInfos(false)
                    setOpenWarningAlert(false)
                    setPCInfos()
                })
            return
        } else {
            enqueueSnackbar('Faction cannot be found', getSnackBarOptions('error'))
        }
    }

    const handleCloseSA = () => { setOpenDialogInfos(false); }
    const handleAddFaction = (event) => {
        setCreatingFaction(true)
        setCurrentFaction({ name: '', rank: '' })
        setEditFaction(true)
        setOpenFactionInfos(true)
    }
    const handleCloseFaction = () => {
        setOpenFactionInfos(false)
        setEditFaction(false)
    }
    const handleFactionCancel = (event) => {
        setOpenFactionInfos(false)
        setEditFaction(false)
    }
    const handleSaveFaction = (event) => {
        if (incorrectFactionData(currentFaction)) {
            enqueueSnackbar(strings.invalidfactiondata, getSnackBarOptions('error'))
            return
        }

        const usr = JSON.parse(sessionStorage.getItem('user'))
        const key = sessionStorage.getItem('key')

        saveFaction(currentFaction, pcId, usr._id, key)
            .then((data) => {
                if (data.status === 'OK') {
                    enqueueSnackbar(strings.savefactionsuccess, getSnackBarOptions('success'))
                    setPCInfos();
                    setCurrentFaction(data.faction)
                    setOpenFactionInfos(false)
                } else {
                    enqueueSnackbar(data.msg, getSnackBarOptions('error'))
                    setOpenFactionInfos(false)
                }
            }).catch((error) => {
                enqueueSnackbar(error, getSnackBarOptions('error'))
                setOpenFactionInfos(false)
                setPageError(true)
            })
            .finally(() => {
                setEditFaction(false)
            })
    }

    const handleCloseWarningAlert = (event) => {
        setOpenWarningAlert(false)
    }

    const handleCloseDialogRedirect = (event) => {
        setopenDialogRedirect(false)
    }

    const handleRedirectToCharacterCreation = (event) => { }

    const changeAttr = (event) => {
        const attr = event.target.dataset.target

        setCurrentFaction(prevState => {
            var buffer = Object.assign({}, prevState)
            buffer[attr] = event.target.value
            return buffer
        })
    }

    const downloadCharacter = (event) => {
        const blob = new Blob([JSON.stringify(characterInfos, null, 2)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${characterInfos.name}.json`;
        link.click();
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setOpenOverwriteWarningAlert(true)
    };

    const handleCloseOverwriteWarningAlert = (event) => {
        setOpenOverwriteWarningAlert(false)

    }

    const handleOverwriteCharacter = (event) => {
        setOpenOverwriteWarningAlert(false)

        const user = JSON.parse(sessionStorage.getItem('user'))
        const key = sessionStorage.getItem('key')

        sendCharacter(SelectedFile, pcId, user._id, key)
            .then((data) => {
                enqueueSnackbar(strings.savesuccess, getSnackBarOptions('success'))
                const dataint = JSON.parse(data)
                setCharacterInfos(dataint.character)
            }).catch((err) => {
                enqueueSnackbar(err, getSnackBarOptions('error'))
                setPageError(true)
            })

    }

    const showInfos = (id) => {
        const log = characterInfos.logs.find(l => l._id === id)
        setMoreInfosLog(log)
        for (var prop in log) {
            console.log(prop + ' : ' + log[prop])
        }
        setOpenMoreInfos(true)
    }

    const handleCloseMoreInfos = () => {
        setOpenMoreInfos(false)
    }

    const renderMoreInfos = () => {
        if (moreInfosLog === undefined) {
            return ''
        }
        const sa = moreInfosLog.storyawards ? moreInfosLog.storyawards.map(sa => sa.name).join(', ') : ''
        const om = moreInfosLog.magicitems ? moreInfosLog.magicitems.map(om => om.name).join(', ') : ''
        const co = moreInfosLog.consumables ? moreInfosLog.consumables.map(co => co.name + ' (' + co.qty + ')').join(', ') : ''

        return (
            <Table>
                <TableRow>
                    <TableCell>{strings.entrytype}</TableCell>
                    <TableCell>{strings[moreInfosLog.type]}</TableCell>
                    <TableCell colSpan={2}>{strings.date}</TableCell>
                    <TableCell colSpan={2}>{new Date(moreInfosLog.date).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings.code}</TableCell>
                    <TableCell colSpan={5}>{moreInfosLog.code}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>{strings.dm}</TableCell>
                    <TableCell colSpan={4}>{moreInfosLog.dm}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings.lvlgain}</TableCell>
                    <TableCell>{moreInfosLog.lvlgain}</TableCell>
                    <TableCell>{strings.gold}</TableCell>
                    <TableCell>{moreInfosLog.goldgain}</TableCell>
                    <TableCell>{strings.dtspent}</TableCell>
                    <TableCell>{moreInfosLog.dtgain}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings.notes}</TableCell>
                    <TableCell colSpan={5}>{moreInfosLog.notes}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings.adventurereward}</TableCell>
                    <TableCell colSpan={5}>{sa}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings.magicitems}</TableCell>
                    <TableCell colSpan={5}>{om}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{strings.consumables}</TableCell>
                    <TableCell colSpan={5}>{co}</TableCell>
                </TableRow>
            </Table>
        )
    }

    if (!contentLoaded) {
        return <CircularProgress sx={{ color: 'Scrollbar' }} />;
    }

    if (pageError) {
        return (
            <ErrorMessage></ErrorMessage>
        )
    }

    return (
        <Grid
            container className='mainContainer'
            sx={containerStyle}
        >
            <Dialog id='card-infos' open={openDialogInfos} onClose={handleCloseSA} sx={modalStyle}>
                <Card sx={{ minWidth: 400 }}>
                    <CardHeader title={dialogTitle} subheader={dialogType} />
                    <CardContent>
                        <Typography variant="body2" color="text.primary">{dialogContent}</Typography>
                    </CardContent>
                </Card>
            </Dialog>
            <Dialog open={openMoreInfos} onClose={handleCloseMoreInfos} sx={modalStyle}>
                <DialogTitle>{strings.moreinfos}</DialogTitle>
                <DialogContent>
                    {renderMoreInfos()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseMoreInfos}>{strings.close}</Button>
                </DialogActions>
            </Dialog>
            <Dialog id='alert-redirect-charactcer' open={openDialogRedirect} onClose={handleCloseDialogRedirect}>
                <DialogTitle id='alert-redirect-charactcer-title'>{strings.warning}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-redirect-charactcer-description-l1">
                        `{strings.redirectionwarning}${strings.charactercreation}`
                    </DialogContentText>
                    <DialogContentText id="alert-redirect-charactcer-description-l2">
                        {strings.redirectionquestion}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRedirectToCharacterCreation} variant="contained" color="warning">{strings.yes} {strings.deletebutton}</Button>
                    <Button onClick={handleCloseDialogRedirect} autoFocus>{strings.no}</Button>
                </DialogActions>
            </Dialog>
            <WarningDialog message={strings.deletewarning} message2={strings.contentlostwarning} open={openWarningAlert}
                handleClose={handleCloseWarningAlert} handleYes={handleDeleteFaction} handleCancel={handleCloseWarningAlert} />
            <WarningDialog message={`${characterInfos.name} ${strings.overwritewargning}`} open={openOverwriteWarningAlert}
                handleClose={handleCloseOverwriteWarningAlert} handleYes={handleOverwriteCharacter} handleCancel={handleCloseOverwriteWarningAlert} />
            <Dialog id='faction' open={openFactionInfos} onClose={handleCloseFaction} sw={modalStyle}>
                <Grid container sx={{ padding: '20px', width: '600px' }}>
                    <Grid item xs={12}>
                        {editFaction ?
                            <TextField label={strings.factionname} value={currentFaction.name} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'name' }} />
                            : <TextFieldLabel text={currentFaction.name} />}
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '10px' }}>
                        {editFaction ?
                            <TextField label={strings.factionrank} value={currentFaction.rank} fullWidth onChange={changeAttr} inputProps={{ 'data-target': 'rank' }} />
                            : <TextFieldLabel text={currentFaction.rank} />}
                    </Grid>
                    {editableCharacter ?
                        <Grid item xs={2} sx={{ paddingTop: '10px' }}>
                            {editFaction ?
                                <Button variant='contained' onClick={handleSaveFaction} disabled={factionHasErrors}>{strings.save}</Button>
                                : <Button variant='contained' onClick={() => setEditFaction(true)}>{strings.edit}</Button>}
                        </Grid> : ''}
                    <Grid item xs={4} sx={{ paddingTop: '10px' }}>
                        {editFaction ?
                            <Button variant='outlined' onClick={handleFactionCancel}>{strings.cancel}</Button>
                            : <Button variant='outlined' onClick={handleFactionCancel}>{strings.close}</Button>}
                    </Grid>
                    {editableCharacter && editFaction && creatingFaction === false ?
                        <Grid item xs={6} sx={{ paddingTop: '10px' }} >
                            <Box justifyContent='flex-end' sx={{ display: 'flex' }}>
                                <Button variant="contained" color="error" onClick={() => { setOpenWarningAlert(true) }}>{strings.deletebutton}</Button>
                            </Box>
                        </Grid> : ''
                    }
                </Grid>
            </Dialog>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item lg={10}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {CharacterRibbon(characterInfos)}
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5'>
                                            {strings.factions}
                                        </Typography>
                                    </Grid>
                                    {characterInfos.factions.map((faction) => (
                                        <Grid item xs={4}>
                                            <Paper className='clickable cartouche' onClick={() => handleEditFaction(faction._id)}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <ItemEntry title={faction.name} desc={faction.rank} />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    ))}
                                    {editableCharacter ? <Grid item xs={2}>
                                        <Paper className='clickable cartouche hoverClickHint' onClick={handleAddFaction}>
                                            <AddCircleOutlineIcon sx={{ width: '100%' }} />
                                        </Paper>
                                    </Grid> : ''}
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5'>
                                            {strings.storyawards}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ height: '10em' }}>
                                        <Scrollbar>
                                            <Grid container>
                                                {characterInfos.storyAwards.map((saward) => (
                                                    <Grid item xs={12} sx={{ marginBottom: '0.5em' }} fullWidth>
                                                        <Paper className='clickable cartouche' onClick={e => handleOpenDetails(e, saward.name, strings.storyaward, saward.desc)}>
                                                            <Grid container>
                                                                <Grid item xs={12} sx={{ margin: 'auto' }}>
                                                                    <ItemEntry title={saward.name} desc={saward.desc} />
                                                                </Grid>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Scrollbar>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5'>
                                            {strings.magicitems}
                                        </Typography>
                                    </Grid>
                                    {!characterInfos.magicitems ? '' :
                                        characterInfos.magicitems.filter(mi => mi.inactive === undefined || mi.inactive === false).map((item) => (
                                            <Grid item xs={4} sx={{ width: '100%', height: '100%' }}>
                                                <Paper className='clickable cartouche' onClick={e => handleOpenDetails(e, item.name, strings[item.rarity], item.desc)}>
                                                    <ItemEntry title={item.name} desc={strings[item.rarity]} />
                                                </Paper>
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5'>
                                            {strings.consumables}
                                        </Typography>
                                    </Grid>
                                    {characterInfos.consumables.map((item) => (
                                        <Grid item xs={4} sx={{ width: '100%', height: '100%' }}>
                                            <Paper className='clickable cartouche' onClick={e => handleOpenDetails(e, item.name, strings.consumable, `${strings.quantity}: ${item.qty}`, item.desc)}>
                                                <ItemEntry title={item.name} desc={`${strings.quantity}: ${item.qty}`} />
                                            </Paper>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={2} >
                        <Image src={characterInfos.url} fit='scale-down' height='200px' />
                        {editableCharacter ? <Button fullWidth onClick={downloadCharacter}>{strings.downloadbackup}</Button> : ''}
                        {editableCharacter ? <Button fullWidth component="label">
                            <input hidden accept=".json" multiple type="file" onChange={handleFileChange} />
                            {strings.uploadbackup}
                        </Button> : ''}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <Typography variant="h6" noWrap={true}>
                    {strings.characterslogs}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {editableCharacter ?
                    <LinkContainer to={`/infos/${pcId}/addlog/`}>
                        <Box justifyContent='flex-start' sx={{ display: 'flex', maxWidth: '10em' }}>
                            <Box className='clickable cartouche hoverClickHint' onClick={handleAddFaction}>
                                <AddCircleOutlineIcon />
                                {strings.addentry}
                            </Box>
                        </Box>
                    </LinkContainer>
                    : ''}
            </Grid>
            <Grid item xs={12} sx={{ maxHeight: '25em' }}>
                <Scrollbar>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                {characterInfos.logs.map((log) => (
                                    <TableRow key={log._id}>
                                        <TableCell component='th' scope='row'>
                                            {new Date(log.date).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {strings[log.type]}
                                        </TableCell>
                                        <TableCell>
                                            {getRowTitle(log)}
                                        </TableCell>
                                        <TableCell>
                                            <Stack>
                                                <Typography>{log.goldgain && log.goldgain !== 0 ? `${log.goldgain} ${strings.gp}` : ''}</Typography>
                                                <Typography>{log.type !== 'adventure' && log.dtgain && log.dtgain !== 0 ? `${log.dtgain} ${strings.dt}` : ''}</Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {log.lvlgain && log.lvlgain !== '0' ? '+' + log.lvlgain + ' ' + strings.lvlup : ''}
                                        </TableCell>
                                        <TableCell>
                                            <Stack>
                                                {log.storyAwards ? log.storyAwards.map((mi) => (
                                                    <Typography variant='body2'>
                                                        {mi.title}
                                                    </Typography>
                                                )) : ''}
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {magicItemStack(log)}
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            <Button variant='outlined' onClick={() => showInfos(log._id)}>{strings.moreinfos}</Button>
                                        </TableCell>
                                        {editButton(log)}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Scrollbar>
            </Grid>
        </Grid >
    )

    function setPCInfos() {
        const user = JSON.parse(sessionStorage.getItem('user'))
        const key = sessionStorage.getItem('key')
        const uid = user ? user._id : undefined

        getPC(pcId, uid, key).then((data) => {
            setCharacterInfos(data);
            setContentLoaded(true);
        })
            .catch((error) => {
                enqueueSnackbar(error, getSnackBarOptions('error'));
            });
    }

    function getRowTitle(rowLog) {
        if (rowLog.type === 'adventure') {
            return (rowLog.code)
        }
        if (rowLog.type === 'dmreward' || rowLog.type === 'dmrewardlocked') {
            return (`${strings.dmreward} : ${strings[rowLog.dmrewardtype]}`)
        }
        if (rowLog.type === 'purchase') {
            return (strings.purchase + ' : ' + rowLog.notes)
        }
        if (rowLog.type === 'trade') {
            return (strings.trade + ' : ' + rowLog.notes)
        }
        if (rowLog.type === 'dtspend') {
            return (strings.dtspent + ' : ' + rowLog.notes)
        }
        if (rowLog.type === 'charactercreation') {
            return (rowLog.notes)
        }
        return ('Unknown entry : ' + rowLog.type)
    }

    function magicItemStack(log) {
        try {
            return <Stack>
                {log.magicitems.filter(mi => mi.inactive === undefined || mi.inactive === false).map((mi) => (
                    <Typography variant='body2'>
                        {mi.name}
                    </Typography>
                ))}
            </Stack>;
        } catch (err) {
            console.log(err)
            return <Stack>nope</Stack>
        }
    }

    function editButton(log) {
        if (log.type === 'charactercreation') {
            const params = new URLSearchParams({
                source: 'charinfos'
            });

            return editableCharacter && <TableCell>
                <LinkContainer to={{ pathname: `/edit/${pcId}`, search: `?${params.toString()}` }}>
                    <Box justifyContent='flex-start' sx={{ display: 'flex' }}>
                        <Box className='clickable cartouche hoverClickHint'>
                            <EditIcon />
                        </Box>
                    </Box>
                </LinkContainer>
            </TableCell>
        }
        return editableCharacter && log.type !== 'dmrewardlocked' ? <TableCell>
            <LinkContainer to={`/infos/${pcId}/log/${log._id}`}>
                <Box justifyContent='flex-start' sx={{ display: 'flex' }}>
                    <Box className='clickable cartouche hoverClickHint'>
                        <EditIcon />
                    </Box>
                </Box>
            </LinkContainer>
        </TableCell> : '';
    }
}

function incorrectFactionData(currentFaction) {
    return (currentFaction.name === undefined || currentFaction.name.trim() === '') ||
        (currentFaction.rank === undefined || currentFaction.rank.trim() === '');
}
