import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { getSnackBarOptions } from "../common/common";
import strings from "./localization";

const appId = '1060942155520151672'
const appKey = 'PVGl7WqZAbE59EUtLAvuvJq3Qb-Enje1'
const redirectDiscordApi = `${process.env.REACT_APP_HOME_URL}/login`
const apiurl = process.env.REACT_APP_API_URL

export default function Login() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)

    const [hasTried, setHasTried] = useState(false)

    var userCode = urlParams.get('code')

    useEffect(() => {

        if (hasTried || userCode === undefined) return

        const params = new URLSearchParams();
        params.append('client_id', appId);
        params.append('client_secret', appKey);
        params.append('grant_type', 'authorization_code');
        params.append('code', userCode);
        params.append('redirect_uri', redirectDiscordApi);
        params.append('scope', 'identify');

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        fetch('https://discord.com/api/v10/oauth2/token', { method: 'post', body: params, headers: headers })
            .then((res) => res.json())
            .then((json) => {
                const receivedAccessToken = json['access_token'];
                if (!receivedAccessToken) {
                    console.error('AccessToken not found')
                    navigate('/')
                    throw Error('AccessToken not found')
                }

                const headers = {
                    'authorization': `Bearer ${receivedAccessToken}`
                }
                fetch('https://discord.com/api/users/@me', { method: 'get', headers: headers })
                    .then((res) => res.json())
                    .then((json) => {
                        const usr = json
                        fetch(`${apiurl}/users/login`, { headers: { 'Content-Type': 'application/json' }, method: 'post', body: JSON.stringify(json) })
                            .then((res) => res.json())
                            .then((json) => {
                                setHasTried(true)

                                const content = JSON.parse(json)
                                usr._id = content._id
                                sessionStorage.setItem('user', JSON.stringify(usr))
                                sessionStorage.setItem('key', content.key)

                                navigate('/')
                            })
                            .catch((error) => {
                                setHasTried(true)

                                sessionStorage.removeItem('user')
                                sessionStorage.removeItem('key')
                                enqueueSnackbar(error, getSnackBarOptions('error'))
                                const options = getSnackBarOptions('warning')
                                options.onExited = () => {
                                    navigate('/');
                                }
                                enqueueSnackbar(strings.redirecting, options)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                        setHasTried(true)

                        sessionStorage.removeItem('user')
                        sessionStorage.removeItem('key')
                        enqueueSnackbar(error, getSnackBarOptions('error'))
                        const options = getSnackBarOptions('warning')
                        options.onExited = () => {
                            navigate('/');
                        }
                        enqueueSnackbar(strings.redirecting, options)
                    })
            })
            .catch((error) => {
                console.log(error)
                setHasTried(true)

                enqueueSnackbar(error, getSnackBarOptions('error'))
                const options = getSnackBarOptions('warning')
                options.onExited = () => {
                    navigate('/');
                }
                enqueueSnackbar(strings.redirecting, options)
            });
    }, [userCode])

    return <CircularProgress sx={{ color: 'Scrollbar' }} />;

}