import { Box, Button, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';

import { useEffect, useState } from "react";
import { getUser, sendDmLogs } from "../repository/repository";
import strings from "./localization";
import { containerStyle } from "./styles/common";
import { useSnackbar } from "notistack";
import { getSnackBarOptions } from "../common/common";
import { useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import WarningDialog from "../warningDialog";
import { ErrorMessage } from "./ErrorMessage";


export default function DMLogs() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true);
    const [pageError, setPageError] = useState(false)

    const [logs, setLogs] = useState([])
    const [tokens, setToken] = useState(0)
    const [showInterface, setShowInterface] = useState(false)
    const [SelectedFile, setSelectedFile] = useState(false)
    const [openOverwriteWarningAlert, setOpenOverwriteWarningAlert] = useState(false);

    const user = JSON.parse(sessionStorage.getItem('user'))

    useEffect(() => {
        if (!user) {
            enqueueSnackbar(strings.nouser, getSnackBarOptions('error'))
            navigate('/')
            return
        }

        if (!loading || !user) return

        const key = sessionStorage.getItem('key')
        getUser(key, user._id)
            .then((data) => {
                if (data.status === 'OK') {
                    setToken(data.user.craftingtokens ?? 0)
                    setLogs(data.user.dmlogs)
                    setLoading(false)
                    setShowInterface(true)
                } else {
                    enqueueSnackbar(data.msg, getSnackBarOptions('error'))
                    const options = getSnackBarOptions('warning')
                    options.onExited = () => {
                        navigate('/');
                    }
                    enqueueSnackbar(strings.redirecting, options)
                    setPageError(true)
                }
            })
            .catch((error) => {
                enqueueSnackbar(error, getSnackBarOptions('error'))
                setPageError(true)
            })
    }, [user, enqueueSnackbar, loading, navigate])

    const downloadDmLogs = (event) => {
        const blob = new Blob([JSON.stringify({ userid: user._id, logs: logs }, null, 2)], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${user.username}-dmlogs.json`;
        link.click();
    }

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setOpenOverwriteWarningAlert(true)
    }

    const handleCloseOverwriteWarningAlert = (event) => {
        setOpenOverwriteWarningAlert(false)
    }

    const handleOverwriteLogs = (event) => {
        setOpenOverwriteWarningAlert(false)

        const user = JSON.parse(sessionStorage.getItem('user'))
        const key = sessionStorage.getItem('key')

        sendDmLogs(SelectedFile, user._id, key)
            .then((resp) => {
                enqueueSnackbar(strings.savesuccess, getSnackBarOptions('success'))
                const dataint = JSON.parse(resp)
                setLogs(dataint.logs.logs)
            }).catch((err) => {
                enqueueSnackbar(err, getSnackBarOptions('error'))
                setPageError(true)
            })
    }

    if (pageError) {
        return <ErrorMessage />
    }

    if (loading) {
        return <CircularProgress sx={{ color: 'Scrollbar' }} />;
    }

    return (
        <Grid
            container className='mainContainer'
            sx={containerStyle}
        >
            <WarningDialog message={`${strings.dmlogs} ${strings.overwritewargning}`} open={openOverwriteWarningAlert}
                handleClose={handleCloseOverwriteWarningAlert} handleYes={handleOverwriteLogs} handleCancel={handleCloseOverwriteWarningAlert} />
            {showInterface ? <Grid item xs={8}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={10}>
                                    <LinkContainer to={`/createdmlog`}>
                                        <Box justifyContent='flex-start' sx={{ display: 'flex' }}>
                                            <Box className='clickable cartouche hoverClickHint'>
                                                <AddCircleOutlineIcon />
                                                {strings.addentry}
                                            </Box>
                                        </Box>
                                    </LinkContainer>
                                </TableCell>
                            </TableRow>
                            {logs.map(l => {
                                return (
                                    <TableRow key={l._id}>
                                        <TableCell component='th' scope='row'>
                                            {new Date(l.date).toLocaleString()}
                                        </TableCell>
                                        <TableCell>
                                            {l.code}
                                        </TableCell>
                                        <TableCell>
                                            {`${strings.session} ${l.session}`}
                                        </TableCell>
                                        <TableCell>
                                            {l.type === '' ? strings.unset : strings[l.type]}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton aria-label="edit" href={`/dmlog/${l._id}`}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid> : ''}
            {showInterface ? <Grid item xs={4}>
                <Grid container>
                    <Grid xs={12}>
                        {strings.availabletokens} : {tokens}
                    </Grid>
                    <Grid xs={12}>
                        <Button fullWidth onClick={downloadDmLogs}>{strings.downloadbackup}</Button>
                        <Button fullWidth component="label">
                            <input hidden accept=".json" multiple type="file" onChange={handleFileChange} />
                            {strings.uploadbackup}
                        </Button>
                    </Grid>
                </Grid>
            </Grid> : ''}

        </Grid>
    )
}