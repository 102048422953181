import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    componentDidCatch(error, info) {
      // log the error to an error reporting service
      console.error(error, info);
      // update state to display fallback UI
      this.setState({ hasError: true });
    }
  
    render() {
      if (this.state.hasError) {
        // display fallback UI
        return <h1>Something went wrong.</h1>;
      }
      // render children
      return this.props.children;
    }
  }
  

  export default ErrorBoundary;