import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
    en: {
        home: 'Home',
        welcome: 'Welcome',
        logout: 'Logout',
        login: 'Log In',
        gp: 'GP',
        dt: 'DT',
        lvlup: 'lvl up',
        dmreward: 'DM Reward',
        moreinfos: 'More Infos',
        create: 'Create',
        purchase: 'Purchase',
        class: 'Class',
        race: 'Race',
        level: 'Level',
        dtspent: 'DT Spent',
        trade: 'Trade',
        gold: 'Gold',
        downtime: 'Downtime',
        factions: 'Factions',
        storyawards: 'Story Awards',
        storyaward: 'Story Award',
        days: 'day(s)',
        magicitems: 'Magic Items',
        magicitem: 'Magic Item',
        consumables: 'Consumables',
        consumable: 'Consumable',
        characterslogs: "Character's Log",
        addentry: 'Add entry',
        infoslogs: 'Infos/Logs',
        infoslogsshort: 'Logs',
        editcharacter: 'Edit PC',
        editcharactershort: 'Edit',
        date: "Date and Time",
        code: "Code",
        lvlgain: "Lvl gained",
        editadventure: "Edit Adventure",
        bluecrystals: "Blue Crystals",
        notes: "Notes",
        notloggedin: "You are not logged in",
        plzlogin: "Please login using Discord to see, create and edit your characters",
        dm: "Dungeon Master",
        entrytype: "Entry type",
        adventure: "Adventure",
        description: "Description",
        logginin: "Loging you in",
        name: 'Name',
        cannotbeempty: 'The following field(s) cannot be empty : ',
        setting: "Setting",
        dmlogs: "DM Logs",
        session: "Session",
        reward: "Reward",
        gameinfos: "Game informations",
        dateassigned: "Assignment date",
        adventurereward: "Adventure Reward",
        crafttoken: "Crafting token",
        none: "None",
        character: "Character",
        unset: "Not set",
        availabletokens: "Available tokens",
        nbtoken: "Number of tokens",
        rarity: "Rarity",
        common: "Common",
        uncommon: "Uncommon",
        rare: "Rare",
        veryrare: "Very rare",
        desc: "Description",
        quantity: "Quantity",
        save: "Save",
        cancel: "Cancel",
        factionname: "Faction's name",
        factionrank: "Faction's rank",
        cannotshowcontent: "Content cannot be shown",
        savesuccess: "Save successful",
        redirecting: "Redirecting you ...",
        redirectionwarning: "You will be redirected to ",
        redirectionquestion: "Go to that page ?",
        savefactionsuccess: "Faction successfully saved",
        close: "Close",
        edit: "Edit",
        warning: "Warning",
        deletewarning: "Really delete ?",
        deletewarningnoquestionmark: "Really delete",
        deletebutton: "DELETE",
        yes: "Yes",
        no: "No",
        contentlostwarning: "Content will be lost",
        usernotfound: "Cannot access user informations",
        return: "Return",
        notFound: "not found",
        tryReloading: "Try reloading the page",
        legendary: "Legendary",
        takeLevelUp: "Level up character",
        cannotreachserver: "Server is unreachable",
        startingGold: "Starting Gold",
        charactercreation: "Character Creation",
        invalidfactiondata: "Invalid data concerning faction",
        logentry: "log entry",
        deletesuccess: "Deletion successful",
        al: "Adventurer's League",
        cc: "Cadre Comunautaire",
        downloadbackup: "Download backup",
        uploadbackup: "Upload backup",
        ok: "OK",
        overwritewargning: "will be overwritten",
        continue: "Continue",
        nouser: "No user loggedin",
        foundbug: "I found a bug",
        send: "Send",
        creationdate: "Creation Date",
        dtspend: "Downtime spent",
        dmrewardlocked: "DM reward"
    },
    fr: {
        home: 'Accueil',
        welcome: 'Bienvenue',
        logout: 'Déconnexion',
        create: 'Créer'
    }
});

export default strings